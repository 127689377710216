import { AppButtons, Box, Container, useIcon } from '@components'
import Image from 'next/image';
import React from 'react'
import RateInfoCard from './rate-info-card'
import SectionSeperator from '../layout/section-seperator';

const RateInfoSection = () => {
    const { PeoplesIcon, CoffeeIcon, PizzaIcon } = useIcon();
    return (
        <SectionSeperator className='relative'>
            <Image layout='fill' src="/images/home/2.png" alt='rate-info' objectFit='cover' />
            <Container>
                <Box className='grid grid-cols-3 gap-8 mb-8'>
                    <RateInfoCard
                        icon={<PeoplesIcon />}
                        count={56.1}
                        description="Million people in the world already saving food with us" />
                    <RateInfoCard
                        variant='secondary'
                        icon={<CoffeeIcon />}
                        count={150.111}
                        description="cafes, restaurants, supermarkets, bakeries, hotels (and more!) on board
                    " />
                    <RateInfoCard
                        icon={<PizzaIcon />}
                        count={132}
                        description="millions meals saved, and counting....!
                    " />
                </Box>
                <Box className='flex justify-center'>
                    <AppButtons size='lg' />
                </Box>
            </Container>
        </SectionSeperator>
    )
}

export default RateInfoSection