import { Box, Paragraph, Span } from '@components';
import classnames from 'classnames';
import React from 'react'

type Props = {
    icon: React.ReactNode
    count: number;
    description: string
    variant?: 'primary' | 'secondary' | undefined
}

const RateInfoCard = ({ icon, count, description, variant = 'primary' }: Props) => {
    const classProps = classnames(
        variant === 'primary' ? 'bg-[#1C6162]' : '',
        variant === 'secondary' ? 'bg-[#33BC92]' : '',
    )

    return (
        <Box className={`${classProps} flex flex-col rounded-2xl items-center  pt-10 pb-8 px-8 z-10`}>
            <Span className='border border-white w-28 h-28  flex items-center justify-center rounded-full'>
                {icon}
            </Span>
            <Span className='font-semibold text-4xl text-white inline-block mt-6'>{count}</Span>
            <Paragraph className='text-lg leading-8 text-white text-center mt-5'>{description}</Paragraph>
        </Box>
    )
}

export default RateInfoCard